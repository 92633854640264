<template>
  <div class="container">
    <h3>菜单管理</h3>
    <el-table
      :data="menus"
      size="mini"
      style="width: 100%"
      row-key="id"
      border
      :indent="24"
      :default-expand-all="false"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="name" label="菜单名称" width="180"></el-table-column>
      <el-table-column prop="path" label="路径/Code"></el-table-column>
      <el-table-column prop="icon" label="图标"></el-table-column>
      <el-table-column prop="type" label="类型" width="100">
        <template slot-scope="scope">
          {{ scope.row.type == 0 ? '菜单' : '按钮' }}
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="排序" width="50"></el-table-column>
      <el-table-column prop="action" label="操作">
        <template slot="header">
          <el-button round size="mini" @click="addMenu(null)">添加根菜单</el-button>
        </template>
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="addMenu(scope.row)" v-if="scope.row.type == 0">
            添加
          </el-button>
          <el-button type="text" size="mini" @click="modifyMenu(scope.row)">修改</el-button>
          <el-button
            type="text"
            v-if="!scope.row.children"
            size="mini"
            @click="removeMenu(scope.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="菜单" :close-on-click-modal="false" :visible.sync="dialogVisible" width="30%">
      <div>
        <el-form size="mini" :model="menu" label-width="120px">
          <el-form-item label="菜单名称">
            <el-input v-model="menu.name"></el-input>
          </el-form-item>

          <el-form-item label="路由/Code">
            <el-input v-model="menu.path"></el-input>
          </el-form-item>

          <el-form-item label="类型">
            <el-select v-model="menu.type" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Component">
            <el-input v-model="menu.component"></el-input>
          </el-form-item>

          <el-form-item label="Icon">
            <el-input v-model="menu.icon"></el-input>
          </el-form-item>

          <el-form-item label="Sort">
            <el-input v-model="menu.sort"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="saveMenu">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSysMenus, deleteSysMenu, saveSysMenu } from '@/common/rbac.js';

export default {
  data() {
    return {
      menus: [],
      dialogVisible: false,
      options: [
        { label: '菜单', value: 0 },
        { label: '按钮', value: 1 },
      ],
      menu: {
        name: '',
        path: '',
        type: 0,
        pid: 0,
        component: '',
        icon: '',
        sort: 0,
      },
    };
  },
  async created() {
    await this.load();
  },
  methods: {
    async load() {
      let resp = await getSysMenus();
      console.log(resp);
      this.menus = resp.result;
    },
    addMenu(row) {
      console.log(row);
      let pid = 0;
      if (row != null) {
        pid = row.id;
      }
      this.menu = {
        name: '',
        path: '',
        type: 0,
        pid: pid,
        component: '',
        icon: '',
        sort: 0,
      };

      this.dialogVisible = true;
    },
    modifyMenu(row) {
      this.menu = row;
      this.dialogVisible = true;
    },
    async saveMenu() {
      let param = this.menu;
      if (!param.id) {
        param.id = 0;
      }

      let resp = await saveSysMenu(param);
      if (resp.code == 200) {
        this.$notify({
          title: '成功',
          message: '数据保存成功',
          type: 'success',
        });
        this.dialogVisible = false;
        await this.load();
      }
    },
    async removeMenu(row) {
      let param = { id: row.id };
      let resp = await deleteSysMenu(param);
      if (resp.code == 200) {
        this.$notify({
          title: '成功',
          message: '数据删除成功',
          type: 'success',
        });
        this.dialogVisible = false;
        await this.load();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
